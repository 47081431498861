import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  LocationsSorted,
  ContactForm,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class LocationsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <div className={"hero"}>
              <h1>Locations</h1>
              <HeroElement
                header={""}
                tagline={""}
                images={getPageHeroImage({
                  options: fishermanBusinessWebsitePage.components,
                })}
                ctaInverted={true}
                ctaColor={"white"}
                containerColor={"primary"}
                textColor={"white"}
                showMultiple={true}
                overlay={true}
                fullWidth={true}
                parallax={false}
                containerAs={"none"}
                height={500}
                gutter={false}
              />
            </div>
          }
        >
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <LocationsSorted
                locations={fishermanBusiness.locations.map((location) => {
                  const locationNameToOrderLink = {
                    "58th St Location":
                      "https://www.facebook.com/TexasHotDogs/",
                    "12th Ave Location":
                      "https://www.facebook.com/Texas-Hot-Dogs-Downtown-450445795446020/",
                  };
                  return {
                    ...location,
                    actions: [
                      {
                        url: locationNameToOrderLink[location.name],
                        label: "Facebook",
                      },
                    ],
                  };
                })}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <Grid.Column width={16}>
                <ContactForm
                  buttonText={"Send"}
                  centeredHeaders={true}
                  className={""}
                  fluidButton={true}
                  header={"Contact Us"}
                  inline={false}
                  showLabels={true}
                  subheader={""}
                  termsCopy={""}
                  withContainer={true}
                  locationId={fishermanBusiness.primaryLocation._id}
                  businessId={fishermanBusiness._id}
                  businessType={fishermanBusiness.type}
                />
              </Grid.Column>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      locations {
        name
        timezone
        phoneNumber
        email
        hours {
          open
          close
          day
          label
        }
        street
        city
        state
        zipCode
        country
        latitude
        longitude
      }
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Locations" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
